document.addEventListener("turbolinks:load", () => {
  const bulkCreateButton = document.getElementById("bulk-create-button");
  const bulkCancelButton = document.getElementById("bulk-cancel-button");
  const bulkCreateMessage = document.getElementById("bulk-create-message");
  const deadlineDateField = document.querySelector(
    ".js-deadline-for-reply-date",
  ) as HTMLInputElement;
  const deadlineTimeField = document.querySelector(
    ".js-deadline-for-reply-time",
  ) as HTMLInputElement;
  const notifyCheckbox = document.getElementById(
    "event_notify",
  ) as HTMLInputElement;
  const notifyLabel = document.querySelector("label[for='event_notify']");
  const form = document.getElementById("bill-form") as HTMLFormElement;
  const submitButton = document.querySelector(
    ".js-submit-button",
  ) as HTMLElement;
  const dialogToggleButton = document.querySelector(
    ".js-dialog-v2-toggle-switch",
  ) as HTMLElement;

  if (
    !(bulkCreateButton && bulkCancelButton && bulkCreateMessage &&
      deadlineDateField && deadlineTimeField && notifyCheckbox &&
      notifyLabel && form && submitButton && dialogToggleButton)
  ) {
    return;
  }

  const originalAction = form.action;
  const bulkAction = form.dataset.bulkAction; // 一括で作成するエンドポイントがセットされている
  const csrfToken = document.querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  bulkCreateButton.addEventListener("click", () => {
    bulkCreateMessage.style.display = "block";
    bulkCreateButton.style.display = "none";
    bulkCancelButton.style.display = "block";
    deadlineDateField.setAttribute("disabled", "true");
    deadlineTimeField.setAttribute("disabled", "true");
    notifyCheckbox.setAttribute("disabled", "true");
    notifyLabel.classList.add("grayed-out");
    form.action = bulkAction;
    submitButton.style.display = "none";
    dialogToggleButton.style.display = "block";

    if (deadlineDateField.value) {
      deadlineDateField.value = "";
    }
    if (deadlineTimeField.value) {
      deadlineTimeField.value = "";
    }
    if (notifyCheckbox.checked) {
      notifyCheckbox.checked = false;
    }

    // CSRF tokenを再設定
    const csrfInput = document.createElement("input");
    csrfInput.type = "hidden";
    csrfInput.name = "authenticity_token";
    csrfInput.value = csrfToken;
    form.appendChild(csrfInput);
  });

  bulkCancelButton.addEventListener("click", () => {
    bulkCreateMessage.style.display = "none";
    bulkCreateButton.style.display = "block";
    bulkCancelButton.style.display = "none";
    deadlineDateField.removeAttribute("disabled");
    deadlineTimeField.removeAttribute("disabled");
    notifyCheckbox.removeAttribute("disabled");
    notifyLabel.classList.remove("grayed-out");
    form.action = originalAction;
    submitButton.style.display = "block";
    dialogToggleButton.style.display = "none";

    // CSRF tokenを削除
    const csrfInput = form.querySelector(
      'input[name="authenticity_token"]',
    );
    if (csrfInput) {
      form.removeChild(csrfInput);
    }
  });
});
